<template>
  <div class="container about">
    <h1>History</h1>

    <b-card title=""
        tag="article"
        class="mb-2">

      <b-card-text>
        <List @method_catch_all="methodCatchAll"
            @refresh_state_local="refreshStateLocal"
            :history="history"
            :filter="filter"></List>
      </b-card-text>

      <!--      <b-button href="#"-->
      <!--          variant="primary">Laad meer items ..-->
      <!--      </b-button>-->
    </b-card>

  </div>
</template>

<script>
// @ is an alias to /src
import List from '@/components/history/List'
import {RequestHelper} from "@/entities/helpers/RequestHelper";

export default {
  name      : 'History',
  components: {
    List
  },
  props     : [
    'filter'
  ],
  data() {
    return {
      loading_data: false,
      history     : undefined
    }
  },
  created   : async function() {
    const that = this;

    that.request = new RequestHelper(that);
  },
  methods   : {
    /**
     *
     * @param args
     * @returns {Promise<void>}
     */
    async methodCatchAll(args) {
      if (args[1] === undefined) {
        args[1] = {};
      }

      // console.log(['methodCatchAll +1'], args[0], args[1]);

      this.$emit('method_catch_all', [args[0], args[1]])
    },
    /**
     *
     * @param args
     * @returns {Promise<boolean>}
     */
    async refreshStateLocal(args) {
      // ### ROOT ###
      const that = this;
      if (args[1] === undefined) {
        args[1] = {};
      }

      // todo env
      // if (!isProduction) {
      console.log(['HISTORY - refreshStateLocal'], args[0], args[1]);
      // }

      if (args[0] === 'history') {
        that.loading_data = true;

        that.history = await this.request.queryPost(
            {filter: that.filter}, 'ocr/history');
      }

      return true;
    },
  }
}
</script>

<style lang="scss"
    scoped>
//h1 {
//  color: red;
//}
</style>