<template>
  <div class="result-final">
    <b-table striped
        class="mb-0"
        small
        bordered="bordered"
        hover
        head-variant="dark"
        :items="items_primary">

      <template #cell(Correct)="data">
        <span v-html="data.value"></span>
      </template>

    </b-table>

    <h6 class="mt-3"
        style="font-size:12px; color: red;">NEW ALGO</h6>

    <b-table striped
        class="mb-0"
        small
        bordered="bordered"
        hover
        head-variant="dark"
        :items="items_primary_new">

      <template #cell(Correct)="data">
        <span v-html="data.value"></span>
      </template>

    </b-table>

    <br />

    <b-table striped
        class="mb-0"
        small
        bordered="bordered"
        hover
        head-variant="unset"
        :items="items_attr"></b-table>

    <br />

    <h6 class="mt-2">Ruwe data</h6>
    <div class="mt-1 ocr_result_msg"
        v-html="ocr_result_msg"></div>

    <br />

    <img :src=getFilePathFullThumb(result_current) />
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */

import {RequestHelper} from "@/entities/helpers/RequestHelper";
import {Ocr} from "@/entities/webcam/Ocr";

export default {
  name   : "Result",
  props  : [
    'result_current',
    'history_file_dir',
  ],
  data() {
    const that = this;

    let itemsPrimary    = [];
    let itemsPrimaryNew = [];
    let itemsAttr       = [];
    let ocrResultMsg    = "";

    if (that.result_current.ocr_result_final !== null) {
      // COMPLETE
      const ocrResultFinal = JSON.parse(that.result_current.ocr_result_final);

      /////////////////////
      // get result correct, visual values

      let modelCorrect = '';
      if (ocrResultFinal.model_res_correct !== undefined) {
        modelCorrect = '<i class="text-danger fas fa-times"></i>'
        if (ocrResultFinal.model_res_correct === 1) {
          modelCorrect = '<i class="text-success fas fa-check"></i>'
        }
      }

      let artikelNummerCorrect = '';
      if (ocrResultFinal.artikel_nummer_res_correct !== undefined) {
        artikelNummerCorrect = '<i class="text-danger fas fa-times"></i>'
        if (ocrResultFinal.artikel_nummer_res_correct === 1) {
          artikelNummerCorrect = '<i class="text-success fas fa-check"></i>'
        }
      }

      let versieNummerCorrect = '';
      if (ocrResultFinal.versie_nummer_res_correct !== undefined) {
        versieNummerCorrect = '<i class="text-danger fas fa-times"></i>'
        if (ocrResultFinal.versie_nummer_res_correct === 1) {
          versieNummerCorrect = '<i class="text-success fas fa-check"></i>'
        }
      }

      let serieNummerCorrect = '';
      if (ocrResultFinal.serie_nummer_res_correct !== undefined) {
        serieNummerCorrect = '<i class="text-danger fas fa-times"></i>'
        if (ocrResultFinal.serie_nummer_res_correct === 1) {
          serieNummerCorrect = '<i class="text-success fas fa-check"></i>'
        }
      }

      /////////////////////
      // create item collections

      itemsPrimary = [
        {Label: 'Model', Waarde: ocrResultFinal.model, Correct: modelCorrect},
        {Label: 'Artikelnummer', Waarde: ocrResultFinal.artikelNummer, Correct: artikelNummerCorrect},
        {Label: 'Versienummer', Waarde: ocrResultFinal.versieNummer, Correct: versieNummerCorrect},
        {Label: 'Serienummer', Waarde: ocrResultFinal.serieNummer, Correct: serieNummerCorrect}
      ]

      if (ocrResultFinal.label_brand !== undefined) {
        itemsAttr.push({Label: 'Product merk', Waarde: ocrResultFinal.label_brand});
      }
      if (ocrResultFinal.label_product_group !== undefined) {
        itemsAttr.push({Label: 'Product group', Waarde: ocrResultFinal.label_product_group});
      }
      if (ocrResultFinal.scan_user_name !== undefined) {
        itemsAttr.push({Label: 'Gebruikersnaam', Waarde: ocrResultFinal.scan_user_name});
      }
      if (ocrResultFinal.scan_mobile_brand !== undefined) {
        itemsAttr.push({Label: 'Scan device brand', Waarde: ocrResultFinal.scan_mobile_brand});
      }
      if (ocrResultFinal.scan_device !== undefined) {
        itemsAttr.push({Label: 'Scan device camera', Waarde: ocrResultFinal.scan_device});
      }
      if (ocrResultFinal.scan_location_description !== undefined) {
        itemsAttr.push({Label: 'Locatie beschrijving', Waarde: ocrResultFinal.scan_location_description});
      }

      if (ocrResultFinal.ocr_result_msg !== undefined) {
        ocrResultMsg = ocrResultFinal.ocr_result_msg;
      }
    }
    else if (that.result_current.ocr_result_msg !== null) {
      // NOT COMPLETE
      const ocrResultMsgArray = JSON.parse(that.result_current.ocr_result_msg);

      itemsPrimary = [
        {Label: 'Model', Waarde: ocrResultMsgArray.model},
        {Label: 'Artikelnummer', Waarde: ocrResultMsgArray.artikelNummer},
        {Label: 'Versienummer', Waarde: ocrResultMsgArray.versieNummer},
        {Label: 'Serienummer', Waarde: ocrResultMsgArray.serieNummer}
      ]

      if (ocrResultMsgArray.ocr_result_msg !== undefined) {
        ocrResultMsg = ocrResultMsgArray.ocr_result_msg;
      }
    }

    // RE:OCR
    that.ocr             = new Ocr();
    const ocrTextRaw     = JSON.parse(that.result_current.ocr_result_raw);
    const ocrResultRowId = ocrTextRaw.ocr_result_row_id;
    const ocrText        = that.ocr.getOCRTextFromRaw(ocrTextRaw)

    itemsPrimaryNew = [
      {Label: 'Model', Waarde: ocrText.model, Correct: ''},
      {Label: 'Artikelnummer', Waarde: ocrText.artikelNummer, Correct: ''},
      {Label: 'Versienummer', Waarde: ocrText.versieNummer, Correct: ''},
      {Label: 'Serienummer', Waarde: ocrText.serieNummer, Correct: ''}
    ]

    return {
      items_primary    : itemsPrimary,
      items_primary_new: itemsPrimaryNew,
      items_attr       : itemsAttr,
      ocr_result_msg   : ocrResultMsg
    }
  },
  created: async function() {
    const that = this;

    // console.log('Result :: created', that.result_current, that.result_current.model);

    that.requestHelper = new RequestHelper(that);
  },
  methods: {
    /**
     *
     * @returns {*}
     * @param row
     */
    getFilePathFullThumb: function(row) {
      const that = this;

      return that.requestHelper.baseUrl + that.history_file_dir + row.file_name_max
    },
  }
}
</script>

<style lang="scss"
    scoped>
img {
  width: 100%;
}

.ocr_result_msg {
  font-size: 12px;
}

.table-result {
  th, td {
    padding: 2px 5px;
    font-size: 14px;

    input, select {
      cursor: pointer;
    }
  }
}
</style>

<!-- NON-SCOPED -->
<style lang="scss">

.result-final table {
  th, td {
    padding-left: 10px;
    font-size: 14px;
  }
}
</style>