<template>
  <div>

    <!--    https://bootstrap-vue.org/docs/components/table#tables-->

    <ul class="wrapper"
        v-if="history !== undefined"
        style="display: flex; ">
      <li v-if="history.list.length === 0">...</li>
      <li v-else
          v-for="(row, index) in history.list"
          :key="index">

        <div class="list-item">
          <div @click="showModal(index)"
              class="list-item-id">#{{ row.id }}
          </div>

          <div v-if="row.result_correct_p"
              @click="showModal(index)"
              class="list-item-correct-p">{{ row.result_correct_p }}%
          </div>

          <div class="list-item-remove btn btn-danger"
              @click="deleteScan(row.id)"><i class="far fa-trash-alt"></i>
          </div>

          <img @click="showModal(index)"
              :src=getFilePathFullThumb(row) />
          <div class="mt-1 mb-3">
            {{ dateShort(row.created_at) }}
            :: {{ dateTimeFriendly(row.created_at) }}

            <!-- filter:all -->
            <span v-if="filter === 'all'">
              <span class="ml-1 list-item-icon list-item-icon-check"
                  v-if="row.ocr_result_final !== null">
                  <i class="fas fa-check"></i>
              </span>
              <span v-else
                  class="ml-1 list-item-icon list-item-icon">
                <i class="fas fa-minus-circle"></i>
              </span>
            </span>

          </div>
        </div>

      </li>
    </ul>

    <ul class="wrapper"
        v-else>
      <li>Data is loading.. <i style="position: relative; top: 2px;"
          class="fas fa-spinner fa-spin"></i></li>
    </ul>

    <b-modal ref="my-modal"
        class="modal-scoped"
        hide-footer
        size="xl"
        centered
        title="Resultaat">

      <result_final :result_current="result_current"
          :history_file_dir="history_file_dir"></result_final>

    </b-modal>

  </div>
</template>

<script>
/* eslint-disable no-unused-vars,vue/no-unused-components */

import {RequestHelper} from "@/entities/helpers/RequestHelper";
import {DataHelper} from "@/entities/helpers/DataHelper";
import ResultFinal from "@/components/history/list/ResultFinal";
import moment from 'moment'
import 'moment/locale/nl'  // without this line it didn't work
moment.locale('nl')

export default {
  name      : "List",
  props     : [
    'history',
    'filter',
  ],
  components: {
    result_final: ResultFinal
  },
  data() {
    return {
      result_current  : {},
      history_file_dir: ""
    }
  },
  created   : async function() {
    const that = this;

    that.requestHelper = new RequestHelper(that);
    that.dataHelper    = new DataHelper(that);

    that.$emit('refresh_state_local', [
      'history', {}
    ]);
  },
  methods   : {
    /**
     *
     * @returns {*}
     * @param row
     */
    getFilePathFullThumb: function(row) {
      const that = this;

      return that.requestHelper.baseUrl + that.history.file_dir + row.file_name_thumb
    },
    /**
     *
     * @param index
     */
    showModal(index) {
      const that = this;

      that.result_current   = that.dataHelper.getFlatData(that.history.list[index]);
      that.history_file_dir = that.history.file_dir;

      console.log('list : showModal', index, that.result_current);

      this.$refs['my-modal'].show()
    },
    /**
     */
    async deleteScan(rowId) {
      const that = this;

      if (confirm("Weet je dat zeker?")) {
        const resOcrResultFinal = await that.requestHelper.queryPost(
            {ocr_result_row_id: rowId},
            'ocr/delete'
        );

        that.$emit('refresh_state_local', [
          'history', {}
        ]);
      }
    },
    /**
     *
     * @param dateTime
     * @returns {string}
     */
    dateTimeFriendly    : function(dateTime) {
      return moment(dateTime).fromNow(true);
    },
    /**
     *
     * @param dateTime
     * @returns {string}
     */
    dateShort           : function(dateTime) {
      return moment(dateTime).format("D MMM");
    },
    hideModal() {
      const that = this;

      this.$refs['my-modal'].hide()
    },
  }
}
</script>

<style lang="scss"
    scoped>

$mqDesktop: 600px;
$imgWidthMob: 140px;
$imgWidth: 250px;

.wrapper {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  align-content: center;
  padding: 0;
  //margin: 5px 0 30px 0;
  margin: 5px 0 0px 0;
}


.wrapper > li {
  flex: 1 1 $imgWidthMob;
  @media only screen and (min-width: $mqDesktop) {
    flex: 1 1 $imgWidth;
  }

  img {
    min-height: 200px;
    width: $imgWidthMob;
    @media only screen and (min-width: $mqDesktop) {
      width: $imgWidth;
    }
  }
}

.list-item {
  position: relative;
  font-size: 13px;

  &-id, &-correct-p, &-remove {
    cursor: pointer;
    position: absolute;
    width: 34px;
    height: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #fff;
    font-size: 12px;
    padding: 5px;
    border-radius: 50%;
    box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.45);
  }

  &-id {
    top: 5px;
    left: 1%;
    background: #eaeaea;
  }

  &-correct-p {
    top: 45px;
    left: 1%;
    background: #cbeeea;
  }

  &-remove {
    top: 105px;
    left: 1%;
    width: 44px;
    height: 44px;
  }

  &-icon {
    position: relative;
    top: 1px;
  }

  &-icon-check {
    color: green;
  }

  &-icon-cross {
    color: red;
  }
}
</style>